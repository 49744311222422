import React from "react"

const SignOutPage = () => {
  return (
    <div>
      sign out
    </div>
  )
}

export default SignOutPage
